



































































import {
  defineComponent,
} from '@nuxtjs/composition-api';

import { MODAL_NAMES, SimpleModal } from '~/components/common/SimpleModal';

export default defineComponent({
  name: 'WarrantyModal',
  components: {
    SimpleModal,
  },
  setup() {
    return {
      name: MODAL_NAMES.warranty,
    };
  },
});
